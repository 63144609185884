import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';

const DocumentUpload = () => {
  const {state} = useLocation();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const [errors,setErrors]=useState();
  const [ toastText,setToastText ] = useState('');
  const [file, setFile] = useState(
      {
        id:state ? state.id : '',
        trade_lic_image:'' ,
        gst_image:'',
        pan_image:'',
      }
  );
  const [filePreview, setFilePreview] = useState(
      {
        trade_lic_image:'' ,
        gst_image:'',
        pan_image:'',
      }
  );
  function handleChangePan(e) {
    setFilePreview({...filePreview, pan_image:URL.createObjectURL(e.target.files[0])});
    
    setFile({...file, pan_image:e.target.files[0]});
  }
  function handleChangeGst(e) {
    setFilePreview({...filePreview, gst_image:URL.createObjectURL(e.target.files[0])});
    setFile({...file, gst_image:e.target.files[0]});
  }
  function handleChangeTrade(e) {
    setFilePreview({...filePreview, trade_lic_image:URL.createObjectURL(e.target.files[0])});
    setFile({...file, trade_lic_image:e.target.files[0]});
  }

const uploadDocument = async() =>{
  setProgress(true)
  // console.log("file",file);
  const formData = new FormData();
  formData.append("id", file.id);
  formData.append("pan_image", file.pan_image);
  formData.append("gst_image", file.gst_image);
  formData.append("trade_lic_image", file.trade_lic_image);
  // console.log(file.trade_lic_image,"api trade");
  try {
      axios.defaults.headers.common['headers'] = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
      const response = await axios.post(`seller/registration/document`,formData);
      console.log(response.data,"file");
      if (response.data.success) {
        setProgress(false)
        setToastText("File Uploaded")
        toast.success(toastText,{hideProgressBar:true})
        navigate('/registration/registrationApprovalPage');
      } else {
        setProgress(false)
      }
      
    } catch (error) {
      console.log(error);
      setProgress(false)
      if (error.response.status==400) {
        // console.log(error.response.data.errors.otp,'catch')
        setErrors(error.response.data.errors);
      } else {
      
      }
    }
}

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="responsive-xs responsive-sm">
            {progress &&
      <div style={{backgroundColor:'#c1bdbdcf',position:'absolute', zIndex:999, width:'100%', height:'100vh'}}>
        <div style={styles.loader}>
            <div className="loaderInner">
                <Spinner animation="border" size="xl" variant='warning'></Spinner>
             </div>
            <p>Uploading Document</p>
            <p style={{marginTop: -17}}>Please, wait...</p>
        </div>
        </div>
}
      <ToastContainer position="top-center"  autoClose={3000}  hideProgressBar={false}  newestOnTop={false}  closeOnClick  rtl={false}  pauseOnFocusLoss  draggable  pauseOnHover  theme={'light'}/>
      {/* Header */}
      <div className="companyBusinessDetailsPageHeader">
        <div style={{ width: "15%" }}>
          <img src="/ooooo.png" className="site-logo" />
        </div>
        <div>
          <Link to="/registration" style={{ textDecorationLine: "none" }}>
            <div className="login">Create New Account</div>
          </Link>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="a-section legal-page-fragment ng-scope">
            <div className="a-box seller_message_box">
              <div className="a-box-inner">
                <h1>Upload Document</h1>
                <form>
                  <div className="row">
                    <div className="col-md-8 ">
                      <div className="row mt-5">
                        <div className="col-md-6 ">
                          <label className="form-label" style={{ fontSize: 18 }}>
                            Pan Card
                          </label>
                          <input type="file" className="form-control" onChange={handleChangePan}/>
                          {errors && errors.pan_image && (<p style={{color: 'red',fontSize:12}}>{errors.pan_image}</p>)}
                        </div>
                        <div className="col-md-6 mt-1">
                          <div className="uploadImageBox" style={{width: 100}}>
                            <img
                              src={filePreview && filePreview.pan_image !== '' ? filePreview.pan_image: '/homePage/icon2.png'}
                              style={{ width: 100,height: 100 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6 ">
                          <label className="form-label" style={{ fontSize: 18 }}>
                            Trade Licence
                          </label>
                          <input type="file" className="form-control" onChange={handleChangeTrade}/>
                          {errors && errors.trade_lic_image && (<p style={{color: 'red',fontSize:12}}>{errors.trade_lic_image}</p>)}
                        </div>
                        <div className="col-md-6 mt-1">
                          <div className="uploadImageBox" style={{width: 100}}>
                            <img
                              src={filePreview && filePreview.trade_lic_image  !== '' ? filePreview.trade_lic_image : '/homePage/icon2.png'}
                              style={{ width: 100,height: 100 }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6 ">
                          <label className="form-label" style={{ fontSize: 18 }} >GST</label>
                          <input type="file" className="form-control" onChange={handleChangeGst}/>
                          {errors && errors.gst_image && (<p style={{color: 'red',fontSize:12}}>{errors.gst_image}</p>)}
                        </div>
                        
                        <div className="col-md-6 mt-1">
                          <div className="uploadImageBox" style={{width: 100}}>
                            <img
                              src={filePreview && filePreview.gst_image !== '' ? filePreview.gst_image: '/homePage/icon2.png'}
                              style={{ width: 100,height: 100 }}
                            />

                          </div>
                        </div>
                      </div>
                      <p style={{color: 'grey',fontSize:12, marginBottom :0}}> *Images Type Should be jpg, png, jpeg</p>
                      <p style={{color: 'grey',fontSize:12}}> *Max size Limit is 2MB</p>
                    </div>
                    <div className="col-md-4 ">
                      <img src="/uploadDocument.png" />
                    </div>
                  </div>
                  <div className="mt-5 d-flex justify-content-center">
                    {/* <Link
                      to="/templete"
                      style={{ textDecorationLine: "none" }}
                      className="submitApprovalBtn">
                      Submit for Approval
                    </Link> */}
                     <button className="submitApprovalBtn" type="button" onClick={()=>uploadDocument()}>
                     Submit for Approval
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
const styles = {
  loader:{position: 'absolute',right: 0,left: 0,top: 200,zIndex: 1,textAlign: 'center',},
  
}